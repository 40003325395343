var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.isKeyLabelComment)?_c('div',{staticClass:"vd-padding-left-small"},[_c('strong',{staticClass:"vd-margin-right-extra-small"},[_vm._v(_vm._s(_vm.contentIndex)+": ")]),_c('span',[_vm._v(_vm._s(_vm.contentDataFormat))])]):_c('div',{staticClass:"vd-padding-left-small"},[_c('div',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:(_vm.collapseId),expression:"collapseId"}],staticClass:"row no-gutters",on:{"click":function($event){return _vm.selectContentRow(_vm.contentIndex)}}},[_c('strong',{staticClass:"vd-margin-right-extra-small"},[_vm._v(_vm._s(_vm.contentIndex)+": ")]),(!_vm.isCommentFieldEmpty)?_c('span',{staticClass:"vd-a"},[_vm._v(" "+_vm._s(_vm.isContentVisible ? 'View Less' : 'View More')+" ")]):_vm._e()]),_c('b-collapse',{staticClass:"vd-padding-right-medium",attrs:{"id":_vm.collapseId}},[(_vm.contentData.from || _vm.contentData.to)?_c('div',{staticClass:"row no-gutters vd-margin-right-medium vd-margin-top-medium vd-margin-bottom-medium vms-activity-logs__comment--container vd-border-dark-grey"},[_c('div',{class:[
            'vd-border-dark-grey vms-activity-logs__comment--from',
            _vm.isMobileScreen
              ? 'col-12 vms-activity-logs__comment--from__mobile'
              : 'col-6' ]},[_c('div',{staticClass:"vd-black vd-padding-small vd-border-dark-grey vms-activity-logs__comment--heading"},[_c('strong',[_vm._v("From")])]),_c('div',{staticClass:"vd-padding-small",domProps:{"innerHTML":_vm._s(
              _vm.contentData.from || _vm.contentData.to
                ? _vm.contentData.from
                : _vm.contentDataFormat
            )}})]),_c('div',{class:[_vm.isMobileScreen ? 'col-12' : 'col-6']},[_c('div',{staticClass:"vd-black vd-padding-small vd-border-dark-grey vms-activity-logs__comment--heading"},[_c('strong',[_vm._v("To")])]),_c('div',{staticClass:"vd-padding-small",domProps:{"innerHTML":_vm._s(_vm.contentData.to)}})])]):_c('div',{staticClass:"vd-padding-small",domProps:{"innerHTML":_vm._s(_vm.contentDataFormat)}})])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }