<template>
  <div class="row no-gutters vd-position-relative">
    <div
      v-if="activityLog.user_fullname"
      class="vd-margin-right-small vd-margin-left-extra-small"
    >
      <span>{{ activityLog.user_fullname }}</span>
      <span v-if="hasMetadata && activityLog.metadata['Company Name']">
        ({{ activityLog.metadata['Company Name'] }})
      </span>
    </div>

    <div class="vd-margin-right-small">
      <img
        v-if="activityLog.user_photo"
        :src="activityLog.user_photo"
        class="vms-activity-logs__list--profile-photo"
      />

      <div
        v-else-if="activityLog.user_id"
        class="vd-icon-profile vms-activity-logs__list--profile-default"
      ></div>
    </div>

    <div class="vd-dark-grey">
      {{ activityLog.created_at | unixToFormat('DD/MM/YY @ hh:mm a') }}
    </div>
  </div>
</template>

<script>
import { isEmpty } from 'lodash'
import FiltersMixin from '@mixins/filters-mixin'

export default {
  mixins: [FiltersMixin],

  props: {
    activityLog: {
      required: false,
      type: Object,
      default: () => {
        return {}
      },
    },
  },

  computed: {
    hasMetadata() {
      return !isEmpty(this.activityLog.metadata)
    },
  },
}
</script>
