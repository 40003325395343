<template>
  <div>
    <div v-if="!isKeyLabelComment" class="vd-padding-left-small">
      <strong class="vd-margin-right-extra-small">{{ contentIndex }}: </strong>
      <span>{{ contentDataFormat }}</span>
    </div>

    <div v-else class="vd-padding-left-small">
      <div
        v-b-toggle="collapseId"
        class="row no-gutters"
        @click="selectContentRow(contentIndex)"
      >
        <strong class="vd-margin-right-extra-small"
          >{{ contentIndex }}:
        </strong>
        <span v-if="!isCommentFieldEmpty" class="vd-a">
          {{ isContentVisible ? 'View Less' : 'View More' }}
        </span>
      </div>

      <b-collapse :id="collapseId" class="vd-padding-right-medium">
        <div
          v-if="contentData.from || contentData.to"
          class="row no-gutters vd-margin-right-medium vd-margin-top-medium vd-margin-bottom-medium vms-activity-logs__comment--container vd-border-dark-grey"
        >
          <div
            :class="[
              'vd-border-dark-grey vms-activity-logs__comment--from',
              isMobileScreen
                ? 'col-12 vms-activity-logs__comment--from__mobile'
                : 'col-6',
            ]"
          >
            <div
              class="vd-black vd-padding-small vd-border-dark-grey vms-activity-logs__comment--heading"
            >
              <strong>From</strong>
            </div>
            <!-- eslint-disable vue/no-v-html -->
            <div
              class="vd-padding-small"
              v-html="
                contentData.from || contentData.to
                  ? contentData.from
                  : contentDataFormat
              "
            ></div>
          </div>

          <div :class="[isMobileScreen ? 'col-12' : 'col-6']">
            <div
              class="vd-black vd-padding-small vd-border-dark-grey vms-activity-logs__comment--heading"
            >
              <strong>To</strong>
            </div>
            <div class="vd-padding-small" v-html="contentData.to"></div>
          </div>
        </div>

        <div v-else class="vd-padding-small" v-html="contentDataFormat"></div>
      </b-collapse>
    </div>
  </div>
</template>

<script>
import {
  includes,
  toLower,
  isArray,
  isEmpty,
  isNil,
  replace,
  pull,
} from 'lodash'
import momentTz from 'moment-timezone'
import { BCollapse, VBToggle } from 'bootstrap-vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    BCollapse,
  },

  directives: {
    'b-toggle': VBToggle,
  },

  props: {
    contentData: {
      required: false,
      type: [Object, String, Number, Array],
      default: null,
    },

    contentIndex: {
      required: true,
      type: String,
      default: '',
    },

    activityLogKey: {
      required: true,
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      selectedComments: [],
      selectedGoals: [],
      selectedNotes: [],
    }
  },

  computed: {
    ...mapGetters({
      isMobileScreen: 'common/isMobileScreen',
    }),

    isKeyLabelComment() {
      return (
        includes(toLower(this.contentIndex), 'comments') ||
        includes(toLower(this.contentIndex), 'goals') ||
        includes(toLower(this.contentIndex), 'notes') ||
        includes(toLower(this.contentIndex), 'topic') ||
        includes(toLower(this.contentIndex), 'description') ||
        includes(toLower(this.contentIndex), 'video tags') ||
        includes(toLower(this.contentIndex), 'video style') ||
        includes(toLower(this.contentIndex), 'pre-production file')
      )
    },

    collapseId() {
      // convert index key to lower case and no spaces
      const indexConvert = replace(toLower(this.contentIndex), ' ', '-')

      return `activity-log-${this.activityLogKey}-${indexConvert}`
    },

    isCommentFieldEmpty() {
      return isEmpty(this.contentData)
    },

    isContentVisible() {
      if (includes(toLower(this.contentIndex), 'comments')) {
        return includes(this.selectedComments, this.activityLogKey)
      }

      if (includes(toLower(this.contentIndex), 'goals')) {
        return includes(this.selectedGoals, this.activityLogKey)
      }

      if (includes(toLower(this.contentIndex), 'notes')) {
        return includes(this.selectedNotes, this.activityLogKey)
      }

      return false
    },

    // eslint-disable-next-line complexity
    contentDataFormat() {
      const content = this.contentData
      const key = this.contentIndex

      const contentType = typeof content
      const isContentDate =
        includes(toLower(key), 'date') ||
        includes(toLower(key), 'deadline') ||
        includes(toLower(key), 'start time') ||
        includes(toLower(key), 'end time')

      const isExtras = includes(toLower(key), 'extras')

      if (contentType === 'number') {
        if (isContentDate) {
          return this.convertDate(content)
        } else {
          return content
        }
      }

      if (contentType === 'object' && !isNil(content)) {
        if (isArray(content)) {
          return content.join(', ')
        }

        const isFromEmpty = content
          ? isArray(content.from)
            ? isEmpty(content.from)
            : isNil(content.from) || content.from.length === 0
          : true
        const isToEmpty = content
          ? isArray(content.to)
            ? isEmpty(content.to)
            : isNil(content.to) || content.to.length === 0
          : true

        const arrayFrom = isArray(content?.from)
          ? content.from.join(', ')
          : content.from
        const arrayTo = isArray(content?.to)
          ? content?.to.join(', ')
          : content?.to

        if (!isFromEmpty && isToEmpty) {
          if (isContentDate) {
            return `changed from ${this.convertDate(content.from)} to (blank)`
          } else if (isExtras) {
            return `changed from ${arrayFrom} to (blank)`
          } else {
            return `changed from ${content.from} to (blank)`
          }
        } else if (isFromEmpty && !isToEmpty) {
          // from empty and to not empty
          if (isContentDate) {
            return `set to ${this.convertDate(content.to)}`
          } else if (isExtras) {
            return `set to ${arrayTo}`
          } else {
            return `set to ${content.to}`
          }
        } else {
          if (isContentDate) {
            return `changed from ${this.convertDate(
              content.from
            )} to ${this.convertDate(content.to)}`
          } else if (isExtras) {
            return `changed from ${arrayFrom} to ${arrayTo}`
          } else {
            return `changed from ${content.from} to ${content.to}`
          }
        }
      } else if (isExtras && isArray(content)) {
        return content?.join(', ')
      }

      return content
    },
  },

  methods: {
    convertDate(date) {
      return isNil(date) ? '' : momentTz.unix(date).format('DD/MM/YY @ hh:mm a')
    },

    selectContentRow(type) {
      let arrayType = []

      if (includes(toLower(type), 'comments')) {
        arrayType = this.selectedComments
      } else if (includes(toLower(type), 'goals')) {
        arrayType = this.selectedGoals
      } else if (includes(toLower(type), 'notes')) {
        arrayType = this.selectedNotes
      }

      if (includes(arrayType, this.contentIndex)) {
        pull(arrayType, this.contentIndex)
        this.$forceUpdate()
      } else {
        arrayType.push(this.contentIndex)
      }
    },
  },
}
</script>
