<template>
  <div class="row no-gutters vd-position-relative">
    <div class="vd-margin-right-small vms-activity-logs__item--user-photo">
      <img
        v-if="activityLog.user_photo"
        :src="activityLog.user_photo"
        class="vms-activity-logs__list--profile-photo vms-activity-logs__list--profile-photo__mobile"
      />

      <div
        v-else-if="activityLog.user_id"
        class="vd-icon-profile vms-activity-logs__list--profile-default vms-activity-logs__list--profile-default__mobile"
      ></div>
    </div>

    <div>
      <div v-if="activityLog.user_fullname">
        <span>{{ activityLog.user_fullname }}</span>
        <span
          v-if="hasMetadata && activityLog.metadata['Company Name']"
          class="vd-display-block"
        >
          ({{ activityLog.metadata['Company Name'] }})
        </span>
      </div>

      <div class="vd-dark-grey vd-full-width">
        {{ activityLog.created_at | unixToFormat('DD/MM/YY @ hh:mm a') }}
      </div>
    </div>
  </div>
</template>

<script>
import { isEmpty } from 'lodash'
import FiltersMixin from '@mixins/filters-mixin'

export default {
  mixins: [FiltersMixin],

  props: {
    activityLog: {
      required: false,
      type: Object,
      default: () => {
        return {}
      },
    },
  },

  computed: {
    hasMetadata() {
      return !isEmpty(this.activityLog.metadata)
    },
  },
}
</script>
