<template>
  <div>
    <div v-if="isMobileScreen" class="vd-padding-small">
      <activity-log-item-mobile
        v-for="(activityLog, key) in activityLogs.data"
        :key="key"
        :content-key="key"
        :activity-log="activityLog"
        :meta-data="metadata(activityLog)"
      ></activity-log-item-mobile>
    </div>

    <div v-else>
      <activity-log-item-desktop
        v-for="(activityLog, key) in activityLogs.data"
        :key="key"
        :content-key="key"
        :activity-log="activityLog"
        :is-last-log-item="isLastLogItem(key)"
        :meta-data="metadata(activityLog)"
      ></activity-log-item-desktop>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { omitBy, includes, isArray, isNil, pull, size, isEmpty } from 'lodash'
import ActivityLogItemDesktop from '@views/project/logs/components/activity-log-item-desktop'
import ActivityLogItemMobile from '@views/project/logs/components/activity-log-item-mobile'

export default {
  components: {
    ActivityLogItemDesktop,
    ActivityLogItemMobile,
  },

  data() {
    return {
      selectedContent: [],
    }
  },

  computed: {
    ...mapGetters({
      activityLogs: 'project/activity-log/activityLogs',
      isMobileScreen: 'common/isMobileScreen',
    }),
  },

  methods: {
    isLastLogItem(index) {
      return index >= size(this.activityLogs.data) - 1
    },

    metadata(log) {
      return omitBy(log.metadata, (value, key) => {
        const keys = ['Sub Label', 'Company Name', 'Order Job ID']

        // exclude metadata with empty string, undefined, empty array, empty object, and null
        if (isArray(value)) {
          return keys.includes(key) || isEmpty(value)
        } else if (typeof value === 'object' || typeof value === 'undefined') {
          return (
            keys.includes(key) ||
            (value && !value.from && !value.to) ||
            isNil(value)
          )
        }
        return keys.includes(key) || value.length === 0
      })
    },

    selectContentRow(index) {
      const arrayType = this.selectedContent

      if (includes(arrayType, index)) {
        pull(arrayType, index)
        this.$forceUpdate()
      } else {
        arrayType.push(index)
      }
    },
  },
}
</script>
