export default Object.freeze({
  statuses: {
    PROJECT_LOG_SUBJECT_PROJECT_CREATED_CLIENT_HUB: 1,
    PROJECT_LOG_SUBJECT_PROJECT_CREATED_VMS: 2,
    PROJECT_LOG_SUBJECT_PROJECT_CREATED_HUBSPOT: 3,
    PROJECT_LOG_SUBJECT_PROJECT_REBOOKED_CLIENT_HUB: 4,
    PROJECT_LOG_SUBJECT_PROJECT_CANCELLED: 5,
    PROJECT_LOG_SUBJECT_PROJECT_STATUS_UPDATED: 6,
    PROJECT_LOG_SUBJECT_PROJECT_EMAIL_CONFIRMATION_SENT: 7,
    PROJECT_LOG_SUBJECT_PROJECT_EMAIL_CONFIRMATION_SENT_FAILED: 8,
    PROJECT_LOG_SUBJECT_PROJECT_SUMMARY_UPDATED: 9,
    PROJECT_LOG_SUBJECT_PRE_PRODUCTION_JOB_CREATED: 10,
    PROJECT_LOG_SUBJECT_PRE_PRODUCTION_JOB_DOCUMENT_CREATED: 11,
    PROJECT_LOG_SUBJECT_PRE_PRODUCTION_JOB_UPDATED: 12,
    PROJECT_LOG_SUBJECT_PRE_PRODUCTION_JOB_EVENT_UPDATED: 13,
    PROJECT_LOG_SUBJECT_PRE_PRODUCTION_JOB_EVENT_DELETED: 14,
    PROJECT_LOG_SUBJECT_PRE_PRODUCTION_JOB_DELETED: 15,
    PROJECT_LOG_SUBJECT_PRE_PRODUCTION_JOB_STATUS_UPDATED: 16,
    PROJECT_LOG_SUBJECT_PRE_PRODUCTION_JOB_BULK_APPROVED: 17,
    PROJECT_LOG_SUBJECT_PRE_PRODUCTION_JOB_DELIVERY_EMAIL_SENT: 18,
    PROJECT_LOG_SUBJECT_PRE_PRODUCTION_JOB_DELIVERY_EMAIL_SENT_FAILED: 19,
    PROJECT_LOG_SUBJECT_SHOOT_JOB_CREATED: 20,
    PROJECT_LOG_SUBJECT_SHOOT_JOB_UPDATED: 21,
    PROJECT_LOG_SUBJECT_SHOOT_JOB_EVENT_UPDATED: 22,
    PROJECT_LOG_SUBJECT_SHOOT_JOB_EVENT_DELETED: 23,
    PROJECT_LOG_SUBJECT_SHOOT_JOB_DELETED: 24,
    PROJECT_LOG_SUBJECT_SHOOT_JOB_MEET_YOUR_CREW_EMAIL_SENT: 25,
    PROJECT_LOG_SUBJECT_SHOOT_JOB_REVIEW_YOUR_CREW_EMAIL_SENT: 26,
    PROJECT_LOG_SUBJECT_SHOOT_JOB_MEET_YOUR_CREW_EMAIL_SENT_FAILED: 27,
    PROJECT_LOG_SUBJECT_SHOOT_JOB_REVIEW_YOUR_CREW_EMAIL_SENT_FAILED: 28,
    PROJECT_LOG_SUBJECT_SHOOT_JOB_CREW_REVIEW_CREATED: 29,
    PROJECT_LOG_SUBJECT_VIDEO_JOB_CREATED: 30,
    PROJECT_LOG_SUBJECT_VIDEO_JOB_UPDATED: 31,
    PROJECT_LOG_SUBJECT_VIDEO_JOB_EVENT_UPDATED: 32,
    PROJECT_LOG_SUBJECT_VIDEO_JOB_EVENT_DELETED: 33,
    PROJECT_LOG_SUBJECT_VIDEO_JOB_DELETED: 34,
    PROJECT_LOG_SUBJECT_VIDEO_JOB_STATUS_UPDATED: 35,
    PROJECT_LOG_SUBJECT_VIDEO_JOB_UPLOAD_STARTED: 36,
    PROJECT_LOG_SUBJECT_VIDEO_JOB_UPLOAD_SUCCESS: 37,
    PROJECT_LOG_SUBJECT_VIDEO_JOB_UPLOAD_FAILED: 38,
    PROJECT_LOG_SUBJECT_VIDEO_JOB_CHANGE_CREATED: 39,
    PROJECT_LOG_SUBJECT_VIDEO_JOB_CHANGE_UPDATED: 40,
    PROJECT_LOG_SUBJECT_VIDEO_JOB_CHANGE_UPLOADED: 41,
    PROJECT_LOG_SUBJECT_VIDEO_JOB_CHANGE_EMAIL_SENT: 42,
    PROJECT_LOG_SUBJECT_VIDEO_JOB_BULK_CHANGE_EMAIL_SENT: 43,
    PROJECT_LOG_SUBJECT_VIDEO_JOB_CHANGE_EMAIL_SENT_FAILED: 44,
    PROJECT_LOG_SUBJECT_VIDEO_JOB_BULK_CHANGE_EMAIL_SENT_FAILED: 45,
    PROJECT_LOG_SUBJECT_VIDEO_JOB_CHANGE_OPENED: 46,
    PROJECT_LOG_SUBJECT_VIDEO_JOB_CHANGE_CLOSED: 47,
    PROJECT_LOG_SUBJECT_VIDEO_JOB_CHANGE_SUBMITTED: 48,
    PROJECT_LOG_SUBJECT_VIDEO_JOB_CLIENT_CHANGE_REQUEST_SCHEDULED: 49,
    PROJECT_LOG_SUBJECT_VIDEO_JOB_YOUTUBE_UPLOAD_STARTED: 50,
    PROJECT_LOG_SUBJECT_VIDEO_JOB_YOUTUBE_UPLOAD_SUCCESS: 51,
    PROJECT_LOG_SUBJECT_VIDEO_JOB_YOUTUBE_UPLOAD_FAILED: 52,
    PROJECT_LOG_SUBJECT_VIDEO_JOB_BULK_DELIVERY_EMAIL_SENT: 53,
    PROJECT_LOG_SUBJECT_VIDEO_JOB_DELIVERY_EMAIL_SENT_FAILED: 54,
    PROJECT_LOG_SUBJECT_VIDEO_JOB_BULK_DELIVERY_EMAIL_SENT_FAILED: 55,
    PROJECT_LOG_SUBJECT_PRODUCTION_COST_JOB_CREATED: 56,
    PROJECT_LOG_SUBJECT_PRODUCTION_COST_JOB_DELETED: 57,
    PROJECT_LOG_SUBJECT_PRODUCTION_COST_JOB_UPDATED: 58,
    PROJECT_LOG_SUBJECT_DISBURSEMENT_JOB_CREATED: 59,
    PROJECT_LOG_SUBJECT_DISBURSEMENT_JOB_DELETED: 60,
    PROJECT_LOG_SUBJECT_DISBURSEMENT_JOB_UPDATED: 61,
    PROJECT_LOG_SUBJECT_PROJECT_INVOICE_100_PERCENT_INVOICING: 62,
    PROJECT_LOG_SUBJECT_PROJECT_INVOICE_50_PERCENT_INVOICING: 63,
    PROJECT_LOG_SUBJECT_PROJECT_INVOICE_50_PERCENT_INVOICED: 64,
    PROJECT_LOG_SUBJECT_PROJECT_INVOICE_FULLY_INVOICED: 65,
    PROJECT_LOG_SUBJECT_PO_NUMBER_CREATED: 66,
    PROJECT_LOG_SUBJECT_PROJECT_CREDIT_USED: 67,
    PROJECT_LOG_SUBJECT_PROJECT_CREDIT_UPDATED: 68,
    PROJECT_LOG_SUBJECT_PROJECT_CREDIT_REVERTED: 69,
    PROJECT_LOG_SUBJECT_CREDIT_FULLY_APPLIED_AND_PROJECT_INVOICED: 70,
    PROJECT_LOG_SUBJECT_CREDIT_NOT_FULLY_APPLIED_AND_PROJECT_UNINVOICED: 71,
    PROJECT_LOG_SUBJECT_STRIPE_PAYMENT_RECEIVED: 72,
    PROJECT_LOG_SUBJECT_STRIPE_PAYMENT_CLIENT_EMAIL_SENT: 73,
    PROJECT_LOG_SUBJECT_INVOICE_CLIENT_SENT: 74,
    PROJECT_LOG_SUBJECT_PROJECT_CONTACT_ADDED: 75,
    PROJECT_LOG_SUBJECT_PROJECT_CONTACT_DELETED: 76,
    PROJECT_LOG_SUBJECT_INVOICE_CLIENT_MANUAL_SENT: 77,
    PROJECT_LOG_SUBJECT_PROJECT_CANCELLED_EMAIL_SENT: 78,
    PROJECT_LOG_SUBJECT_PROJECT_CANCELLED_EMAIL_SENT_FAILED: 79,
    PROJECT_LOG_SUBJECT_VIDEO_JOB_BULK_ARCHIVED: 80,
    PROJECT_LOG_SUBJECT_PROJECT_INVOICE_UPDATED: 81,
    PROJECT_LOG_SUBJECT_PO_NUMBER_ADDED_EMAIL_SENT: 82,
    PROJECT_LOG_SUBJECT_VIDEO_JOB_DELIVERY_EMAIL_SENT: 83,
    PROJECT_LOG_SUBJECT_PROJECT_ARCHIVED: 84,
    PROJECT_LOG_SUBJECT_INVOICE_DELETED: 85,
    PROJECT_LOG_SUBJECT_PROJECT_REOPENED: 86,
    PROJECT_LOG_SUBJECT_MANUAL_INVOICE_SENT: 87,
    PROJECT_LOG_SUBJECT_INVOICE_LINKED: 88,
    PROJECT_LOG_SUBJECT_SHOOT_JOB_STATUS_UPDATED: 89,
    PROJECT_LOG_SUBJECT_VIDEO_INFORMATION_UPDATED: 90,
    PROJECT_LOG_SUBJECT_PROJECT_INVOICE_UNINVOICED: 91,
    PROJECT_LOG_SUBJECT_PROJECT_HOLD: 92,
    PROJECT_LOG_SUBJECT_PROJECT_UNHOLD: 93,
    PROJECT_LOG_SUBJECT_PRE_PRODUCTION_LINK: 94,
    PROJECT_LOG_SUBJECT_BUDGET_SPREADSHEET_GENERATED: 95,
    PROJECT_LOG_SUBJECT_DISBURSEMENT_BUDGET_APPROVED: 96,
    PROJECT_LOG_SUBJECT_DISBURSEMENT_BUDGET_UPDATED: 97,
    PROJECT_LOG_SUBJECT_FOOTAGE_STATUS_UPDATED: 98,
    PROJECT_LOG_SUBJECT_FOOTAGE_ARCHIVED: 99,
    PROJECT_LOG_SUBJECT_PRE_PRODUCTION_CHANGE_CREATED: 100,
    PROJECT_LOG_SUBJECT_VIDEO_JOB_MARKED_AS_DELIVERED: 101,
  },
})
