import projectLogStatuses from '@configs/project-log-statuses'

export default Object.freeze({
  icons: [
    {
      class: 'vd-icon-folder-open',
      eventTriggers: [
        projectLogStatuses.statuses
          .PROJECT_LOG_SUBJECT_PROJECT_CREATED_CLIENT_HUB,
        projectLogStatuses.statuses.PROJECT_LOG_SUBJECT_PROJECT_CREATED_VMS,
        projectLogStatuses.statuses.PROJECT_LOG_SUBJECT_PROJECT_CREATED_HUBSPOT,
        projectLogStatuses.statuses
          .PROJECT_LOG_SUBJECT_PROJECT_REBOOKED_CLIENT_HUB,
        projectLogStatuses.statuses.PROJECT_LOG_SUBJECT_PROJECT_CANCELLED,
        projectLogStatuses.statuses.PROJECT_LOG_SUBJECT_PROJECT_ARCHIVED,
        projectLogStatuses.statuses.PROJECT_LOG_SUBJECT_PROJECT_REOPENED,
        projectLogStatuses.statuses.PROJECT_LOG_SUBJECT_FOOTAGE_ARCHIVED,
      ],
    },
    {
      class: 'vd-icon-tasks-bullet',
      eventTriggers: [
        projectLogStatuses.statuses.PROJECT_LOG_SUBJECT_PROJECT_STATUS_UPDATED,
        projectLogStatuses.statuses.PROJECT_LOG_SUBJECT_PROJECT_SUMMARY_UPDATED,
        projectLogStatuses.statuses
          .PROJECT_LOG_SUBJECT_PRODUCTION_COST_JOB_CREATED,
        projectLogStatuses.statuses
          .PROJECT_LOG_SUBJECT_PRODUCTION_COST_JOB_DELETED,
        projectLogStatuses.statuses
          .PROJECT_LOG_SUBJECT_PRODUCTION_COST_JOB_UPDATED,
        projectLogStatuses.statuses
          .PROJECT_LOG_SUBJECT_DISBURSEMENT_JOB_CREATED,
        projectLogStatuses.statuses
          .PROJECT_LOG_SUBJECT_DISBURSEMENT_JOB_DELETED,
        projectLogStatuses.statuses
          .PROJECT_LOG_SUBJECT_DISBURSEMENT_JOB_UPDATED,
        projectLogStatuses.statuses.PROJECT_LOG_SUBJECT_PO_NUMBER_CREATED,
        projectLogStatuses.statuses.PROJECT_LOG_SUBJECT_VIDEO_JOB_BULK_ARCHIVED,
      ],
    },
    {
      class: 'vd-icon-mail-grey',
      eventTriggers: [
        projectLogStatuses.statuses
          .PROJECT_LOG_SUBJECT_PROJECT_EMAIL_CONFIRMATION_SENT,
        projectLogStatuses.statuses
          .PROJECT_LOG_SUBJECT_PROJECT_EMAIL_CONFIRMATION_SENT_FAILED,
        projectLogStatuses.statuses
          .PROJECT_LOG_SUBJECT_PRE_PRODUCTION_JOB_DELIVERY_EMAIL_SENT,
        projectLogStatuses.statuses
          .PROJECT_LOG_SUBJECT_PRE_PRODUCTION_JOB_DELIVERY_EMAIL_SENT_FAILED,
        projectLogStatuses.statuses
          .PROJECT_LOG_SUBJECT_SHOOT_JOB_MEET_YOUR_CREW_EMAIL_SENT,
        projectLogStatuses.statuses
          .PROJECT_LOG_SUBJECT_SHOOT_JOB_REVIEW_YOUR_CREW_EMAIL_SENT,
        projectLogStatuses.statuses
          .PROJECT_LOG_SUBJECT_SHOOT_JOB_MEET_YOUR_CREW_EMAIL_SENT_FAILED,
        projectLogStatuses.statuses
          .PROJECT_LOG_SUBJECT_SHOOT_JOB_REVIEW_YOUR_CREW_EMAIL_SENT_FAILED,
        projectLogStatuses.statuses
          .PROJECT_LOG_SUBJECT_VIDEO_JOB_CHANGE_EMAIL_SENT,
        projectLogStatuses.statuses
          .PROJECT_LOG_SUBJECT_VIDEO_JOB_BULK_CHANGE_EMAIL_SENT,
        projectLogStatuses.statuses
          .PROJECT_LOG_SUBJECT_VIDEO_JOB_CHANGE_EMAIL_SENT_FAILED,
        projectLogStatuses.statuses
          .PROJECT_LOG_SUBJECT_VIDEO_JOB_BULK_CHANGE_EMAIL_SENT_FAILED,
        projectLogStatuses.statuses
          .PROJECT_LOG_SUBJECT_VIDEO_JOB_BULK_DELIVERY_EMAIL_SENT,
        projectLogStatuses.statuses
          .PROJECT_LOG_SUBJECT_VIDEO_JOB_DELIVERY_EMAIL_SENT_FAILED,
        projectLogStatuses.statuses
          .PROJECT_LOG_SUBJECT_VIDEO_JOB_BULK_DELIVERY_EMAIL_SENT_FAILED,
        projectLogStatuses.statuses.PROJECT_LOG_SUBJECT_INVOICE_CLIENT_SENT,
        projectLogStatuses.statuses
          .PROJECT_LOG_SUBJECT_INVOICE_CLIENT_MANUAL_SENT,
        projectLogStatuses.statuses
          .PROJECT_LOG_SUBJECT_PROJECT_CANCELLED_EMAIL_SENT,
        projectLogStatuses.statuses
          .PROJECT_LOG_SUBJECT_PROJECT_CANCELLED_EMAIL_SENT_FAILED,
        projectLogStatuses.statuses
          .PROJECT_LOG_SUBJECT_VIDEO_JOB_DELIVERY_EMAIL_SENT,
      ],
    },
    {
      class: 'vd-icon-preprod-shoot',
      eventTriggers: [
        projectLogStatuses.statuses
          .PROJECT_LOG_SUBJECT_PRE_PRODUCTION_JOB_CREATED,
        projectLogStatuses.statuses
          .PROJECT_LOG_SUBJECT_PRE_PRODUCTION_JOB_DOCUMENT_CREATED,
        projectLogStatuses.statuses
          .PROJECT_LOG_SUBJECT_PRE_PRODUCTION_JOB_UPDATED,
        projectLogStatuses.statuses
          .PROJECT_LOG_SUBJECT_PRE_PRODUCTION_JOB_EVENT_UPDATED,
        projectLogStatuses.statuses
          .PROJECT_LOG_SUBJECT_PRE_PRODUCTION_JOB_EVENT_DELETED,
        projectLogStatuses.statuses
          .PROJECT_LOG_SUBJECT_PRE_PRODUCTION_JOB_DELETED,
        projectLogStatuses.statuses
          .PROJECT_LOG_SUBJECT_PRE_PRODUCTION_JOB_STATUS_UPDATED,
        projectLogStatuses.statuses
          .PROJECT_LOG_SUBJECT_PRE_PRODUCTION_JOB_BULK_APPROVED,
        projectLogStatuses.statuses
          .PROJECT_LOG_SUBJECT_SHOOT_JOB_CREW_REVIEW_CREATED,
        projectLogStatuses.statuses.PROJECT_LOG_SUBJECT_PRE_PRODUCTION_LINK,
        projectLogStatuses.statuses
          .PROJECT_LOG_SUBJECT_PRE_PRODUCTION_CHANGE_CREATED,
      ],
    },
    {
      class: 'vd-icon-camcorder',
      eventTriggers: [
        projectLogStatuses.statuses.PROJECT_LOG_SUBJECT_SHOOT_JOB_CREATED,
        projectLogStatuses.statuses.PROJECT_LOG_SUBJECT_SHOOT_JOB_UPDATED,
        projectLogStatuses.statuses.PROJECT_LOG_SUBJECT_SHOOT_JOB_EVENT_UPDATED,
        projectLogStatuses.statuses.PROJECT_LOG_SUBJECT_SHOOT_JOB_EVENT_DELETED,
        projectLogStatuses.statuses.PROJECT_LOG_SUBJECT_SHOOT_JOB_DELETED,
        projectLogStatuses.statuses
          .PROJECT_LOG_SUBJECT_SHOOT_JOB_STATUS_UPDATED,
        projectLogStatuses.statuses.PROJECT_LOG_SUBJECT_FOOTAGE_STATUS_UPDATED,
      ],
    },
    {
      class: 'vd-icon-media',
      eventTriggers: [
        projectLogStatuses.statuses.PROJECT_LOG_SUBJECT_VIDEO_JOB_CREATED,
        projectLogStatuses.statuses.PROJECT_LOG_SUBJECT_VIDEO_JOB_UPDATED,
        projectLogStatuses.statuses.PROJECT_LOG_SUBJECT_VIDEO_JOB_EVENT_UPDATED,
        projectLogStatuses.statuses.PROJECT_LOG_SUBJECT_VIDEO_JOB_EVENT_DELETED,
        projectLogStatuses.statuses.PROJECT_LOG_SUBJECT_VIDEO_JOB_DELETED,
        projectLogStatuses.statuses
          .PROJECT_LOG_SUBJECT_VIDEO_JOB_STATUS_UPDATED,
        projectLogStatuses.statuses
          .PROJECT_LOG_SUBJECT_VIDEO_JOB_UPLOAD_STARTED,
        projectLogStatuses.statuses
          .PROJECT_LOG_SUBJECT_VIDEO_JOB_UPLOAD_SUCCESS,
        projectLogStatuses.statuses.PROJECT_LOG_SUBJECT_VIDEO_JOB_UPLOAD_FAILED,
        projectLogStatuses.statuses
          .PROJECT_LOG_SUBJECT_VIDEO_JOB_CHANGE_CREATED,
        projectLogStatuses.statuses
          .PROJECT_LOG_SUBJECT_VIDEO_JOB_CHANGE_UPDATED,
        projectLogStatuses.statuses
          .PROJECT_LOG_SUBJECT_VIDEO_JOB_CHANGE_UPLOADED,
        projectLogStatuses.statuses.PROJECT_LOG_SUBJECT_VIDEO_JOB_CHANGE_OPENED,
        projectLogStatuses.statuses.PROJECT_LOG_SUBJECT_VIDEO_JOB_CHANGE_CLOSED,
        projectLogStatuses.statuses
          .PROJECT_LOG_SUBJECT_VIDEO_JOB_CHANGE_SUBMITTED,
        projectLogStatuses.statuses
          .PROJECT_LOG_SUBJECT_VIDEO_JOB_CLIENT_CHANGE_REQUEST_SCHEDULED,
        projectLogStatuses.statuses
          .PROJECT_LOG_SUBJECT_VIDEO_JOB_YOUTUBE_UPLOAD_STARTED,
        projectLogStatuses.statuses
          .PROJECT_LOG_SUBJECT_VIDEO_JOB_YOUTUBE_UPLOAD_SUCCESS,
        projectLogStatuses.statuses
          .PROJECT_LOG_SUBJECT_VIDEO_JOB_YOUTUBE_UPLOAD_FAILED,
        projectLogStatuses.statuses
          .PROJECT_LOG_SUBJECT_VIDEO_INFORMATION_UPDATED,
        projectLogStatuses.statuses
          .PROJECT_LOG_SUBJECT_VIDEO_JOB_MARKED_AS_DELIVERED,
      ],
    },
    {
      class: 'vd-icon-credits-grey',
      eventTriggers: [
        projectLogStatuses.statuses
          .PROJECT_LOG_SUBJECT_PROJECT_INVOICE_100_PERCENT_INVOICING,
        projectLogStatuses.statuses
          .PROJECT_LOG_SUBJECT_PROJECT_INVOICE_50_PERCENT_INVOICING,
        projectLogStatuses.statuses
          .PROJECT_LOG_SUBJECT_PROJECT_INVOICE_50_PERCENT_INVOICED,
        projectLogStatuses.statuses
          .PROJECT_LOG_SUBJECT_PROJECT_INVOICE_FULLY_INVOICED,
        projectLogStatuses.statuses.PROJECT_LOG_SUBJECT_PROJECT_CREDIT_USED,
        projectLogStatuses.statuses.PROJECT_LOG_SUBJECT_PROJECT_CREDIT_UPDATED,
        projectLogStatuses.statuses.PROJECT_LOG_SUBJECT_PROJECT_CREDIT_REVERTED,
        projectLogStatuses.statuses
          .PROJECT_LOG_SUBJECT_CREDIT_FULLY_APPLIED_AND_PROJECT_INVOICED,
        projectLogStatuses.statuses
          .PROJECT_LOG_SUBJECT_CREDIT_NOT_FULLY_APPLIED_AND_PROJECT_UNINVOICED,
        projectLogStatuses.statuses.PROJECT_LOG_SUBJECT_STRIPE_PAYMENT_RECEIVED,
        projectLogStatuses.statuses
          .PROJECT_LOG_SUBJECT_STRIPE_PAYMENT_CLIENT_EMAIL_SENT,
        projectLogStatuses.statuses.PROJECT_LOG_SUBJECT_PROJECT_INVOICE_UPDATED,
        projectLogStatuses.statuses
          .PROJECT_LOG_SUBJECT_PO_NUMBER_ADDED_EMAIL_SENT,
        projectLogStatuses.statuses.PROJECT_LOG_SUBJECT_MANUAL_INVOICE_SENT,
        projectLogStatuses.statuses.PROJECT_LOG_SUBJECT_INVOICE_DELETED,
        projectLogStatuses.statuses.PROJECT_LOG_SUBJECT_INVOICE_LINKED,
        projectLogStatuses.statuses
          .PROJECT_LOG_SUBJECT_PROJECT_INVOICE_UNINVOICED,
        projectLogStatuses.statuses
          .PROJECT_LOG_SUBJECT_BUDGET_SPREADSHEET_GENERATED,
        projectLogStatuses.statuses
          .PROJECT_LOG_SUBJECT_DISBURSEMENT_BUDGET_APPROVED,
        projectLogStatuses.statuses
          .PROJECT_LOG_SUBJECT_DISBURSEMENT_BUDGET_UPDATED,
      ],
    },
    {
      class: 'vd-icon-user-circle-grey',
      eventTriggers: [
        projectLogStatuses.statuses.PROJECT_LOG_SUBJECT_PROJECT_CONTACT_ADDED,
        projectLogStatuses.statuses.PROJECT_LOG_SUBJECT_PROJECT_CONTACT_DELETED,
      ],
    },
    {
      class: 'vd-icon-pause-circle',
      eventTriggers: [
        projectLogStatuses.statuses.PROJECT_LOG_SUBJECT_PROJECT_HOLD,
        projectLogStatuses.statuses.PROJECT_LOG_SUBJECT_PROJECT_UNHOLD,
      ],
    },
  ],
})
