<template>
  <div class="vd-margin-small vd-h4">
    <div
      v-b-toggle="`activity-log-${contentKey}`"
      class="row no-gutters"
      @click="toggleContent"
    >
      <div class="vd-display-inline-block">
        <div class="row no-gutters">
          <activity-log-icons :activity-log="activityLog"></activity-log-icons>

          <div class="vd-margin-right-small">
            <div
              v-if="hasMetadata"
              :class="[
                'vms-activity-logs__list--icons-caret',
                isContentVisible
                  ? 'vd-icon-arrow-up-grey-black'
                  : 'vd-icon-arrow-down-grey-black',
              ]"
            ></div>
          </div>

          <activity-log-subject
            :project-subject-label="activityLog.project_log_subject_label"
            :metadata="activityLog.metadata"
          ></activity-log-subject>
        </div>
      </div>

      <div
        class="vd-display-inline-block vms-activity-logs__list--title-divider vd-margin-left-small vd-margin-right-small"
      >
        <hr />
      </div>

      <div class="vd-display-block">
        <activity-log-info-desktop
          :activity-log="activityLog"
        ></activity-log-info-desktop>
      </div>
    </div>

    <div
      :class="[
        'vd-margin-left-small',
        isLastLogItem
          ? ''
          : 'vd-border-light-grey vms-activity-logs__list--divider',
      ]"
    >
      <b-collapse v-if="hasMetadata" :id="`activity-log-${contentKey}`">
        <div
          class="vd-background-lightest-grey vd-dark-grey vd-padding-top-small vd-padding-bottom-small vd-margin-left-20"
        >
          <ul
            class="vms-activity-logs__list--metadata-items vd-margin-bottom-0 vd-padding-left-medium vd-padding-right-medium"
          >
            <li v-for="(data, index) in metaData" :key="index">
              <activity-log-metadata
                :activity-log-key="contentKey"
                :content-data="data"
                :content-index="index"
              ></activity-log-metadata>
            </li>
          </ul>
        </div>
      </b-collapse>
    </div>
  </div>
</template>

<script>
import { isEmpty } from 'lodash'
import { BCollapse, VBToggle } from 'bootstrap-vue'
import ActivityLogIcons from '@views/project/logs/components/activity-log-icons'
import ActivityLogSubject from '@views/project/logs/components/activity-log-subject'
import ActivityLogInfoDesktop from '@views/project/logs/components/activity-log-info-desktop'
import ActivityLogMetadata from '@views/project/logs/components/activity-log-metadata'

export default {
  components: {
    BCollapse,
    ActivityLogIcons,
    ActivityLogSubject,
    ActivityLogInfoDesktop,
    ActivityLogMetadata,
  },

  directives: {
    'b-toggle': VBToggle,
  },

  props: {
    contentKey: {
      type: Number,
      required: true,
    },

    activityLog: {
      type: Object,
      required: false,
      default: () => ({}),
    },

    isLastLogItem: {
      type: Boolean,
      required: false,
      default: false,
    },

    metaData: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },

  data() {
    return {
      isContentVisible: false,
    }
  },

  computed: {
    hasMetadata() {
      return !isEmpty(this.metaData)
    },
  },

  methods: {
    toggleContent() {
      this.isContentVisible = !this.isContentVisible
    },
  },
}
</script>

<style></style>
