<template>
  <div
    :class="[
      'vms-activity-logs',
      isMobileScreen ? 'vms-activity-logs__mobile' : 'vms-content__container',
    ]"
  >
    <default-loader
      v-if="isActivityLogListLoading"
      class="vd-margin-top-extra-large"
    ></default-loader>
    <div v-else class="vms-activity-logs__container">
      <div :class="[!isMobileScreen ? 'vms-activity-logs__content' : '']">
        <empty-activity-logs
          v-if="!hasActivityLogs"
          image-src="/images/empty-states/empty-activity-logs.svg"
          description="There are no activity logs that match your search."
        ></empty-activity-logs>
        <activity-log-list
          v-else
          class="vd-margin-top-medium"
        ></activity-log-list>
      </div>
    </div>
  </div>
</template>

<script>
import activityLogList from '@views/project/logs/activity-log-list'
import DefaultLoader from '@components/loaders/default-loader'
import { mapGetters, mapActions } from 'vuex'
import EmptyActivityLogs from '@components/empty-states/project/project-tab-empty-state'
import { size } from 'lodash'

export default {
  components: { activityLogList, DefaultLoader, EmptyActivityLogs },

  computed: {
    ...mapGetters({
      activityLogs: 'project/activity-log/activityLogs',
      projectDetails: 'project/projectDetails',
      isActivityLogListLoading: 'project/activity-log/isActivityLogListLoading',
      isMobileScreen: 'common/isMobileScreen',
    }),

    hasActivityLogs() {
      return size(this.activityLogs.data) > 0
    },
  },

  methods: {
    ...mapActions({
      getActivityLogs: 'project/activity-log/getActivityLogList',
    }),
  },
}
</script>
<style lang="scss">
@import '@styles/views/project/activity-logs';
</style>
