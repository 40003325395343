<template>
  <div>
    <strong>{{ projectSubjectLabel }}</strong>
    <span
      v-if="hasMetadata && metadata['Sub Label']"
      :class="[
        'vd-light-blue',
        isMobileScreen
          ? 'vd-display-block'
          : 'vd-margin-left-extra-small vd-display-inline-block',
      ]"
    >
      [{{ metadata['Sub Label'] | truncateString(50) }}]
    </span>
  </div>
</template>

<script>
import { isEmpty } from 'lodash'
import FiltersMixin from '@mixins/filters-mixin'
import { mapGetters } from 'vuex'

export default {
  mixins: [FiltersMixin],

  props: {
    projectSubjectLabel: {
      required: true,
      type: String,
      default: '',
    },

    metadata: {
      required: false,
      type: Object,
      default: () => {
        return {}
      },
    },
  },

  computed: {
    ...mapGetters({
      isMobileScreen: 'common/isMobileScreen',
    }),

    hasMetadata() {
      return !isEmpty(this.metadata)
    },
  },
}
</script>
