var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vd-margin-small vd-h4"},[_c('div',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:(("activity-log-" + _vm.contentKey)),expression:"`activity-log-${contentKey}`"}],staticClass:"row no-gutters",on:{"click":_vm.toggleContent}},[_c('div',{staticClass:"vd-display-inline-block"},[_c('div',{staticClass:"row no-gutters"},[_c('activity-log-icons',{attrs:{"activity-log":_vm.activityLog}}),_c('div',{staticClass:"vd-margin-right-small"},[(_vm.hasMetadata)?_c('div',{class:[
              'vms-activity-logs__list--icons-caret',
              _vm.isContentVisible
                ? 'vd-icon-arrow-up-grey-black'
                : 'vd-icon-arrow-down-grey-black' ]}):_vm._e()]),_c('activity-log-subject',{attrs:{"project-subject-label":_vm.activityLog.project_log_subject_label,"metadata":_vm.activityLog.metadata}})],1)]),_vm._m(0),_c('div',{staticClass:"vd-display-block"},[_c('activity-log-info-desktop',{attrs:{"activity-log":_vm.activityLog}})],1)]),_c('div',{class:[
      'vd-margin-left-small',
      _vm.isLastLogItem
        ? ''
        : 'vd-border-light-grey vms-activity-logs__list--divider' ]},[(_vm.hasMetadata)?_c('b-collapse',{attrs:{"id":("activity-log-" + _vm.contentKey)}},[_c('div',{staticClass:"vd-background-lightest-grey vd-dark-grey vd-padding-top-small vd-padding-bottom-small vd-margin-left-20"},[_c('ul',{staticClass:"vms-activity-logs__list--metadata-items vd-margin-bottom-0 vd-padding-left-medium vd-padding-right-medium"},_vm._l((_vm.metaData),function(data,index){return _c('li',{key:index},[_c('activity-log-metadata',{attrs:{"activity-log-key":_vm.contentKey,"content-data":data,"content-index":index}})],1)}),0)])]):_vm._e()],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vd-display-inline-block vms-activity-logs__list--title-divider vd-margin-left-small vd-margin-right-small"},[_c('hr')])}]

export { render, staticRenderFns }