<template>
  <div
    class="vms-activity-logs__item vd-background-white vd-border-width-1 vd-border-light-grey vd-border-radius-6 vd-box-shadow vd-padding-medium vd-margin-bottom-medium vd-margin-top-medium"
  >
    <div
      v-b-toggle="`activity-log-${contentKey}`"
      class="row no-gutters vms-activity-logs__item--header vd-position-relative"
      @click="toggleContent"
    >
      <div
        v-if="hasMetadata"
        :class="[
          'vms-activity-logs__item--icon vms-activity-logs__item--icon__small vd-margin-top-small vd-margin-right-medium',
          isContentVisible
            ? 'vd-icon-arrow-up-grey-black'
            : 'vd-icon-arrow-down-grey-black',
        ]"
      ></div>

      <activity-log-subject
        class="vms-activity-logs__item--subject vd-margin-right-medium"
        :project-subject-label="activityLog.project_log_subject_label"
        :metadata="activityLog.metadata"
      ></activity-log-subject>

      <activity-log-icons
        class="vd-margin-top-extra-small vms-activity-logs__item--icon"
        :activity-log="activityLog"
      ></activity-log-icons>
    </div>

    <hr class="vd-border-grey" />

    <activity-log-info-mobile
      class="vms-activity-logs__item--footer"
      :activity-log="activityLog"
    ></activity-log-info-mobile>

    <b-collapse
      v-if="hasMetadata"
      :id="`activity-log-${contentKey}`"
      class="vd-margin-top-medium"
    >
      <div
        class="vd-background-lightest-grey vd-dark-grey vd-padding-top-small vd-padding-bottom-small vd-border-radius-6"
      >
        <ul
          class="vms-activity-logs__list--metadata-items vd-margin-bottom-0 vd-padding-left-medium vd-padding-right-medium"
        >
          <li v-for="(data, index) in metaData" :key="index">
            <activity-log-metadata
              :activity-log-key="contentKey"
              :content-data="data"
              :content-index="index"
            ></activity-log-metadata>
          </li>
        </ul>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import { isEmpty } from 'lodash'
import { BCollapse, VBToggle } from 'bootstrap-vue'
import ActivityLogIcons from '@views/project/logs/components/activity-log-icons'
import ActivityLogSubject from '@views/project/logs/components/activity-log-subject'
import ActivityLogInfoMobile from '@views/project/logs/components/activity-log-info-mobile'
import ActivityLogMetadata from '@views/project/logs/components/activity-log-metadata'

export default {
  components: {
    BCollapse,
    ActivityLogIcons,
    ActivityLogSubject,
    ActivityLogInfoMobile,
    ActivityLogMetadata,
  },

  directives: {
    'b-toggle': VBToggle,
  },

  props: {
    contentKey: {
      type: Number,
      required: true,
    },

    activityLog: {
      type: Object,
      required: false,
      default: () => ({}),
    },

    metaData: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },

  data() {
    return {
      isContentVisible: false,
    }
  },

  computed: {
    hasMetadata() {
      return !isEmpty(this.metaData)
    },
  },

  methods: {
    toggleContent() {
      this.isContentVisible = !this.isContentVisible
    },
  },
}
</script>

<style></style>
