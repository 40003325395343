<template>
  <div
    :class="[
      'vd-margin-right-small vms-activity-logs__list--icons-log-type',
      iconClass(activityLog),
    ]"
  ></div>
</template>

<script>
import { forEach } from 'lodash'
import projectLogConfig from '@configs/activity-log'

export default {
  props: {
    activityLog: {
      required: true,
      type: Object,
      default: () => {
        return {}
      },
    },
  },

  methods: {
    iconClass(log) {
      let iconClassName = ''

      forEach(projectLogConfig.icons, (icon) => {
        if (icon.eventTriggers.includes(log.project_log_subject_id)) {
          iconClassName = icon.class
          // loop break
          return false
        }
        return true
      })

      return iconClassName
    },
  },
}
</script>
